.App {
  padding: 1em;
  text-align: center;
}
.nbox {
  margin: 0;
  padding: 0;
}
.hrnp {
  margin: 0;
  padding: 0;
}